import React from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';

import Logo from '../../assets/images/logo.png'
import MapImg from '../../assets/images/map.png'
import Shape1 from '../../assets/images/shape1.png'
import Shape2 from '../../assets/images/shape2.svg'

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="footer-area bg-f7fafd">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Dirección</h3>

                            <ul className="footer-contact-info">
                                <li>
                                    <Icon.MapPin />
                                    Carlos Antunez 2025, Oficina 807
                                    <br />
                                    Providencia, Santiago de Chile
                                </li>
                                <li>
                                    <Icon.Mail />
                                    Email: <Link to="mailto:contacto@solutoria.cl">contacto@solutoria.cl</Link>
                                </li>
                            </ul>

                            <ul className="social-links">
                                <li>
                                    <a href="https://web.facebook.com/solutoria/" className="facebook" target="_blank" rel="noreferrer"><Icon.Facebook /></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/solutoria/" className="twitter" target="_blank" rel="noreferrer"><Icon.Twitter /></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/solutoria.cl/" className="instagram" target="_blank" rel="noreferrer"><Icon.Instagram /></a>
                                </li>
                                <li>
                                    <a href="https://cl.linkedin.com/company/solutoria" className="linkedin" target="_blank" rel="noreferrer"><Icon.Linkedin /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <div className="single-footer-widget pull-rigth">
                            <div className="logo">
                                <Link to="/">
                                    <img src={Logo} width={40} height={40} alt="logo" />
                                </Link>
                            </div>
                            <p>Σ Solutoria</p>
                            <p>No olvidamos que nuestras soluciones serán utilizadas por <strong>personas</strong>, por lo que nos esforzamos en maximizar su <strong>experiencia</strong>.</p>
                        </div>
                    </div>


                    <div className="col-lg-12 col-md-12">
                        <div className="copyright-area">
                            <p>Copyright &copy; {currentYear} Todos los derechos reservados <a href="https://www.solutoria.cl/" target="_blank">Σ Solutoria</a></p>
                        </div>
                    </div>
                </div>
            </div>

            <img src={MapImg} className="map" alt="map" />

            {/* Shape Images */}
            <div className="shape1">
                <img src={Shape1} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </footer>
    )

}

export default Footer; 